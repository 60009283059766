<template>
  <div class="jd_main">

    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList">
        <van-sticky>
          <search :search-out="searchData" />
          <filterTabs :options="options" />
        </van-sticky>
        <div class="jd_list_box">
          <div v-for="(item,index) in list" :key="index" class="jd_list" @click="jdList(item.goodsInfo.goods_id)">
            <div class="jd_list_ovs">
              <imgLoading class="jd_list_img" :src="item.goodsInfo.goods_image" />
              <div class="sale_num">
                <!--              <span class="sale_num_style">已售 {{item.goodsInfo.sale_num}} </span>-->
              </div>
            </div>
            <div class="jd_list_son">
              <div class="jd_name">
                <!--              <span class="jd_logo">JD</span>-->
                <span>{{ item.goodsInfo.goods_name }}</span>
              </div>

              <div>
                <div v-if="item.coupon.discount" class="jd_logo_bj" style="display: inline-block">{{ item.coupon.discount }} 元券</div>
                <div class="jd_earn_price" style="display: inline-block;margin-left: 4px">预估赚{{ item.commission_info.earn_price }}</div>
              </div>

              <div class="jd_logo_ov">
                <span class="jd_price">¥{{ item.goodsInfo.jd_price }}</span>
                <span class="jd_original_price">
                  ¥{{ item.goodsInfo.original_price }}
                  <div>已售 {{ item.goodsInfo.sale_num }}</div></span>

              </div>

              <!--            <div class="jd_earn_price">预估赚{{item.commission_info.earn_price}}</div>-->
            </div>
          </div>
        </div>

      </div>
    </mescroll>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import imgLoading from '@/component/imgLoading'
import mescroll from '@/component/fresh_scroll/mescroll'
import search from '@/component/head/search'
import filterTabs from '@/component/filterTabs/index'
import { fetchJDChoiceGoodsList } from '@/services/shop-cps/shop-jd-cps'
export default {
  name: 'JdDetailsList',
  components: {
    imgLoading,
    mescroll,
    search,
    filterTabs
  },
  data() {
    return {
      searchData: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        textColor: '#333',
        titleData: this.$route.query.title, // title 名称
        callbackGo: this.callbackGo // 点击返回 回调
      },

      // guideBar:{
      //   typeGo:"",  // 跳转返回页
      //   titles: this.$route.query.title,  // 标题
      //   btnRefresh:false,  // 刷新显隐
      //   bjColor:"#ff001c",  // 背景颜色
      //   callbackGo:this.callbackGo,  // 点击返回 回调
      //   callbackRefresh:this.callbackRefresh, // 点击刷新 回调（暂时btnRefresh 为隐藏）
      // },

      mescrollDown: {
        use: true,
        // top:'50px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      // ---------------------------
      id: '', // 页面传过来id
      list: '', // list
      page: 1, // 页数
      pageSize: 10, // 一页10条
      options: {
        active: 0,
        activeChange: (index) => {
          if (index === 0) {
            this.parmas.sort = ''
            this.parmas.sortName = ''
          } else if (index === 1) {
            this.parmas.sort = 'asc'
            this.parmas.sortName = 'inOrderCount30DaysSku'
          } else {
            this.parmas.sort = 'asc'
            this.parmas.sortName = 'price'
          }

          // tab 切换
          // 接口
          fetchJDChoiceGoodsList(this.parmas).then(res => {
            if (res.code == 200) {
              this.list = res.data
            }
          })
        },
        tabsList: [
          {
            title: '综合',
            sort: {
              showSort: false,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange1(type)
              }
            }
          },
          {
            title: '销量',
            sort: {
              showSort: true,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange2(type)
              }
            }
          },
          {
            title: '价格',
            sort: {
              showSort: true,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange3(type)
              }
            }
          }
        ]
      },
      parmas: {
        eliteId: this.$route.query.eliteId,
        sortName: '',
        sort: '', // 1价格正序  0 倒序
        page: 1,
        pageSize: 10
      }

    }
  },
  mounted() {
    document.title = this.$route.query.title
    this.jdGitList()
  },
  methods: {
    // 价格 排序方法
    typeChange2(type) {
      if (type == 'add') {
        this.parmas.sort = 'desc'
      } else {
        this.parmas.sort = 'asc'
      }
      fetchJDChoiceGoodsList(this.parmas).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
    },
    typeChange3: function(type) {
      if (type == 'sub') {
        this.parmas.sort = 'desc'
      } else {
        this.parmas.sort = 'asc'
      }
      fetchJDChoiceGoodsList(this.parmas).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
    },

    mescrollInit(data) {
      this.mescroll = data
    },
    // callbackInputGo:function (){
    //   this.$router.push('./goodsSearch');
    // },
    // 处理返回 页面
    callbackGo() {
      this.$router.go(-1)
    },
    // 处理 刷新页面
    callbackRefresh() {
      this.jdGitList()
    },

    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const data = {
        eliteId: this.$route.query.eliteId,
        page: pageNum,
        pageSize: pageSize
      }
      fetchJDChoiceGoodsList(data).then(res => {
        successCallback(res.data)
      })
    },
    // 获取 list 列表
    jdGitList: function() {
      // let data ={
      //   eliteId: this.$route.query.eliteId,
      //   page:this.page,
      //   pageSize:this.pageSize
      // }
      fetchJDChoiceGoodsList(this.parmas).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
    },
    // 点击 list 获取下标 id 跳转京东商品详情
    jdList: function(goodsId) {
      // goodsDetailCPS?goodsId='商品ID'&status=（3:京东；4:拼多多；5:淘宝；6:唯品会）
      this.$router.push({
        path: '/goodsDetailCPS',
        query: {
          goodsId: goodsId,
          status: 3 // 固定 京东3
        }
      })
    }
  }

}
</script>

<style lang="less" scoped>
.jd_main{
  background: #f6f6f6;
  height: 100vh;
}
.jd_list_box{
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  box-sizing: border-box;
  // background: #f6f6f6;
  overflow: hidden;
  .jd_list{
    width: 169px;
    height:290px;
    background: #fff;
    margin-right: 12px;
    float: left;
    margin-bottom: 6px;
    margin-top: 6px;
    border-radius: 6px;
    overflow: hidden;
  }
  .jd_list:nth-child(2n){
    margin-right: 0px;
  }
  .jd_list_ovs{
    position: relative;
    .jd_list_img{
      width: 100%;
    }
    .sale_num{
      width: 100%;
      height: 16%;
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(rgba(255,255,255 ,0), rgba(97 ,96 ,96 ,0.7));
    }
    .sale_num_style{
      position: absolute;
      right: 12px;
      bottom: 6px;
      color: #fff;
      font-size: 14px;
    }
  }

  .jd_list_son{
    padding: 0 7px;
    box-sizing: border-box;
    text-align: left;
    margin-top: 6px;
    .jd_name{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 18px;
      .jd_logo{
        background: #ff001c;
        color: #fff;
        border-radius: 3px;
        padding: 0px 1px;
        font-weight: bold;
        box-sizing: border-box;
        font-size: 8px;
        margin-right: 3px;
      }
    }
    .jd_logo_ov{
      margin-top: 20px;
     overflow: hidden;
      .jd_price{
        color: #ff001c;
        font-size: 16px;
      }
      .jd_original_price{
        text-decoration: line-through;
        color: #bbb;
        font-size: 12px;
        margin-left: 2px;
      }

      div{
        margin-top: 4px;
        float: right;
        font-size: 10px;
      }
    }
    .jd_logo_bj{
      margin-top: 6px;
      background: url("../../assets/images/yhj_red.png") no-repeat;
      background-size: 100% 100%;
      width: 60px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      font-size: 10px;
      color: #fff;
    }
    .jd_earn_price{
      margin-top: 10px;
      border: 1px solid #ff001c;
      border-radius: 4px;
      color: #ff001c;
      background: rgba(230, 153, 153, 0.5);
      display: inline-block;
      padding:1px 4px;
      font-size: 10px;
    }
  }
}

</style>
