/**
 * 京东CPS相关内容
 * 2021-06-02 14:54:27
 * @author SnowRock
 */
import request from '@/services/request'

export function targetUrl(url) {
  return '/shop/v1' + url
}

/**
 * 获取京东首页信息
 * 2021-06-02 14:54:45
 * @author SnowRock
 * @param params 入参
 * @param params.uid 用户ID
 * @returns {Promise<*|undefined>}
 */
export function fetchJDHome(params) {
  return request({
    url: targetUrl('/cpsreforge/jd/jdIndex'),
    method: 'post',
    data: params
  })
}

/**
 * 获取京东首页商品列表
 * 2021-06-02 14:56:27
 * @author SnowRock
 * @param params 入参
 * @param params.classId 分类id ，猜你喜欢为987654321
 * @param params.page 页数
 * @param params.pageSize 码数
 * @param params.mid 用户id
 * @returns {Promise<*|undefined>}
 */
export function fetchJDHomeGoodsList(params) {
  return request({
    url: targetUrl('/cpsreforge/jd/jdHomeGoodsList'),
    method: 'post',
    data: params
  })
}

/**
 * 京粉精选商品查询接口【对应 频道商品列表】
 * 2021-06-02 14:59:25
 * @author SnowRock
 * @param params
 * @param params.eliteId 京东频道ID
 * @param params.sortName 京东品类Id
 * @param params.mid 用户id
 * @param params.page 页数
 * @param params.sort 排序
 * @param params.eliteType 京东类型
 * @returns {Promise<*|undefined>}
 */
export function fetchJDChoiceGoodsList(params) {
  return request({
    url: targetUrl('/cpsreforge/jd/jdJingfen'),
    method: 'post',
    data: params
  })
}

/**
 * 关键词列表
 * 2021-06-02 14:59:25
 * @author SnowRock
 * @param params
 * @param params.keyword 关键词
 * @param params.isCoupon 是否有优惠券
 * @param params.mid 用户id
 * @param params.page 页数
 * @param params.sort 排序
 * @param params.sortName 排序名称
 * @param params.pricefrom 低价格
 * @param params.priceto 高价格
 * @returns {Promise<*|undefined>}
 */
export function fetchJDKeywordsList(params) {
  return request({
    url: targetUrl('/cpsreforge/jd/jdGoodsQuery'),
    method: 'post',
    data: params
  })
}
/**
 * 京东商品详情
 * 2021-06-02 14:59:25
 * @author SnowRock
 * @param params
 * @param params.uid 用户id
 * @param params.goods_id 商品id
 * @param params.eliteType 京东礼金类型id
 * @returns {Promise<*|undefined>}
 */
export function fetchJDGoodsDetails(params) {
  return request({
    url: targetUrl('/cpsreforge/jd/jdGoodsInfo'),
    method: 'post',
    data: params
  })
}
